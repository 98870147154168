<template>
  <Main>
    <div v-if="wallets.data" class="wallets">
      <sdPageHeader :title="i18n.t('menuItems.wallets')">
        <template v-slot:buttons>
          <div class="setting-form-actions">
            <sdButton size="default" type="primary" @click="changeModalVisible">
              {{ i18n.t('profileView.createNewWallet') }}
            </sdButton>
          </div>
        </template>
      </sdPageHeader>
      <a-row type="flex" justify="center">
        <a-col :xs="24">
          <sdCards headless>
            <WalletsTable :wallets="wallets" :isLoading="isLoading" />
          </sdCards>
        </a-col>
      </a-row>
      <CreateWallet
        :isLoading="isLoading"
        :walletCurrencies="walletCurrencies"
        :profileEmail="profileState?.email"
        v-model:visible="modalVisible"
      />
    </div>
    <div class="loading-data" v-else>
      <a-spin size="large"></a-spin>
    </div>
  </Main>
</template>

<script>
import { Main } from '../styled';
import { defineComponent, ref, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { walletCurrencies } from '@/config/helpers/JSONdata';

const CreateWallet = defineAsyncComponent(() => import('./overview/CreateWallet'));
const WalletsTable = defineAsyncComponent(() => import('./overview/WalletsTable'));

const Wallets = defineComponent({
  name: 'Wallets',
  components: { Main, CreateWallet, WalletsTable },

  setup() {
    const { state } = useStore();
    const i18n = useI18n();

    const modalVisible = ref(false);

    const isLoading = computed(() => state.profile.isLoading);
    const profileState = computed(() => state.profile.data);
    const wallets = computed(() => state.profile.wallets);

    const changeModalVisible = () => {
      modalVisible.value = !modalVisible.value;
    };

    return {
      i18n,
      walletCurrencies,
      isLoading,
      modalVisible,
      profileState,
      wallets,
      changeModalVisible,
    };
  },
});

export default Wallets;
</script>

<style lang="scss" scoped>
@import './overview/style';
</style>
